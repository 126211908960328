import React, { useState, useEffect, useRef } from 'react';
import styles from './Chat.module.css';
import Note from './Note';

function Chat({ persona, currentName, currentAvatar, addNote, removeNote }) {
    const [message, setMessage] = useState('');
    const [responses, setResponses] = useState([]);
    const [activeStars, setActiveStars] = useState([]);
    const ws = useRef(null);
    const messagesEndRef = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const model = "gpt-4o";

    const connectWebSocket = () => {
        ws.current = new WebSocket(`${apiUrl}`);

        ws.current.onopen = () => {
            console.log('WebSocket connection opened');
        };

        ws.current.onmessage = (event) => {
            let data = event.data;
            console.log(event.data);

            data = data.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

            const parts = data.split('\n').reduce((acc, part) => {
                if (acc.length > 0) acc.push('<br />');
                acc.push(part);
                return acc;
            }, []);
            setResponses(prevResponses => prevResponses.map((resp, idx, arr) => {
                if (idx === arr.length - 1) {
                    return { ...resp, content: [...resp.content, ...parts] };
                }
                return resp;
            }));
        };

        ws.current.onclose = () => {
            console.log("WebSocket connection closed, retrying...");
            setTimeout(() => connectWebSocket(), 1000);
        };
    };

    useEffect(() => {
        connectWebSocket();

        return () => {
            ws.current.close();
        };
    }, []);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [responses]);

    const sendMessage = () => {
        if (message.trim()) {
            ws.current.send(JSON.stringify({ model: model, currentName: currentName, content: message, persona: persona }));
            setResponses(prevResponses => [
                ...prevResponses,
                { id: prevResponses.length, input: message, content: [], avatar: currentAvatar }
            ]);
            setMessage('');
            resetTextareaHeight();
        }
    };

    const resetTextareaHeight = () => {
        const textarea = document.querySelector(`.${styles.textarea}`);
        if (textarea) {
            textarea.style.height = 'auto';
        }
    };

    const handleStarClick = (response) => {
        if (activeStars.includes(response.id)) {
            setActiveStars(prevActiveStars => prevActiveStars.filter(id => id !== response.id));
            removeNote(response.id);
        } else {
            addNote({
                id: response.id,
                question: response.input,
                response: response.content.join(''),
                avatar: response.avatar
            });
            setActiveStars(prevActiveStars => [...prevActiveStars, response.id]);
        }
    };

    const handleRemoveClick = (responseId) => {
        setResponses(prevResponses => prevResponses.filter(response => response.id !== responseId));
        removeNote(responseId);
        setActiveStars(prevActiveStars => prevActiveStars.filter(id => id !== responseId));
    };

    const handleInputChange = (e) => {
        setMessage(e.target.value);
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    return (
        <div className={styles.chat}>
          <div className={styles.gradient}></div>
            <div className={styles.messages}>
                {responses.map((response) => (
                    <div key={response.id} className={styles.response}>
                        <div className={styles.input}>
                            <div className={styles.star_container}>
                                <div
                                    className={`${styles.star_left} ${activeStars.includes(response.id) ? styles.active : ''}`}
                                    onClick={() => handleStarClick(response)}
                                >
                                    &#9733;
                                </div>
                                <div className={styles.star_center}>{response.input}</div>
                                <div className={styles.star_right} onClick={() => handleRemoveClick(response.id)}>&times;</div>
                            </div>
                        </div>
                        <div className={styles.responseContainer}>
                            <div className={styles.avatarContainer}>
                                <img src={response.avatar} alt="Avatar" className={styles.avatar} />
                            </div>
                            <div className={styles.responseContent}>
                                <div dangerouslySetInnerHTML={{ __html: response.content.join('') }} />
                            </div>
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div className={styles['input-area']}>
                <textarea
                id="promptBox"
                    value={message}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            sendMessage();
                        }
                    }}
                    placeholder="Ask the Egos eco questions based on their perspectives."
                    className={styles.textarea}
                />
                <button id="askButton" className={styles.ask} onClick={sendMessage}>Ask</button>
            </div>
        </div>
    );
}

export default Chat;
